



















































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import {Dict, Comp, Utils, Org} from "@/modules/budget/staffing-table/types";
import PaginationPages from "@/modules/budget/staffing-table/components/PaginationPages.vue";
import { Values } from 'vue-i18n';
import {Ax} from "@/utils";
import {store} from "@/modules/budget/staffing-table/common";

/**
 * Образование
 */
interface EduSubject extends Dict.StEduSubject { id: number }

/**
 * Строка таблицы
 */
interface IRow extends Comp.TableRow<EduSubject> { code: string}

const modelChangeEvent = 'change';


const hasDifference = (list1: string[], list2: string[]): boolean => {
  if (list1.length !== list2.length) {
    return true;
  }
  for (const item of list1) {
    if (!list2.includes(item)) {
      return true;
    }
  }
  return false;

}


const itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [
  { value: 10, text: '10' },
  { value: 15, text: '15' },
  { value: 25, text: '25' },
];

@Component({
  components: {
    Pagination: PaginationPages
  }
})
export default class EduSubjectSelection extends Vue {
  @Model(modelChangeEvent, {
    type: Array,
    default: () => [],
    required: false,
  })
  public readonly value!: string[];

  @Prop({
    type: String,
    required: false,
    default: null
  })
  public readonly orgTypeCode!: string | null ;

    @Prop({
        type: Map,
        required: true,
    })
    public readonly eduSubjMap!: Map<string, Dict.StEduSubject>;

  // region Lifecycle
  // noinspection JSUnusedLocalSymbols
  private created() {
    this.selectedEduSubjects = this.value;

    this.$watch('orgCode', () => {
      this.page = 0
      this.reload();
    });

    this.$watch('itemsPerPage', () => {
      this.page = 0;
      this.reload();
    });

    this.$watch('selectedEduSubjects', () => {
        if (hasDifference(this.value, this.selectedEduSubjects)) {
            this.$emit(modelChangeEvent, this.selectedEduSubject[0]);
        }
    });

    this.$watch('trimmedSearchText', () => {
      this.reload();
    });
  }

  private mounted() {
    this.reloadEduOrgTypes()
    this.reload();

    setTimeout(() => {
      const searchTextField = this.$refs.searchTextField;
      if (searchTextField instanceof Vue) {
        const el = (searchTextField as Vue).$el;
        if (el instanceof HTMLInputElement) {
          el.focus();
        }
      }
    });
  }
  // endregion


  // region Утилиты
  private getTranslate(key: string, values?: Values): string {
    return String(this.$t(`modules.budget.staffing_table.*EduSubjectSelection*.${key}`, values));
  }

  private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
    this.$bvToast.toast(message, {
      title: title,
      variant: type,
      toaster: 'b-toaster-top-center',
      autoHideDelay: 5000,
      appendToast: true
    });
  }

  // region Навигация по страницам
  private itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [...itemsPerPageVariants];

  private itemsPerPage = 10;

  private page = 0;

  private totalItems = 0;

  private onPageChanged(page: number) {
    this.page = page;
    this.reload();
  }
  // endregion

  private loading = false;

  // region список загруженных элементов
  private rows: IRow[] = [];

  // region Текст для поиска
  private searchText = '';

  private get trimmedSearchText(): string {
    return this.searchText.trim();
  }
  // endregion

  private orgCode: string | null = this.orgTypeCode

  // region Выбранные элементы
  private selectedEduSubjects: string[] = [];

  private get selectedEduSubject(): string[] {
    const result: string[] = [];

    this.selectedEduSubjects.forEach(code => {
      result.push(code);
    });

    return result;
  }

  private getEduSubjectFullText(subject: EduSubject): string {
    if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
      return subject.nameKk;
    }
    return subject.nameRu;
  }

    private getEduSubject(item: string): Dict.StEduSubject | null {
        return this.eduSubjMap.get(item) ?? null;
    }

    private getEduSubjectFullTextRu(subject: EduSubject): string {
        return subject.nameRu;
    }


    private getEduSubjectFullTextKk(subject: EduSubject): string {
    return subject.nameKk; }


  private getEduSubjectKindText(subject: EduSubject): string {
    if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
      return subject.kindNameKk ?? '';
    }
    return  subject.kindNameRu ?? '';
  }


  private getEduSubjectShortText(item: string): string {
    const subject = this.getEduSubject(item)

      if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
          return subject?.nameKk.substr(0, 47) + '...' || '';
      }
      return subject?.nameRu.substr(0, 47) + '...' || '';
  }

  // region Строки таблицы
  private get fields(): Comp.TableFieldDef[] {
    const dataField = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
      return {
        key: `data.${dataKey}`,
        label: this.getTranslate(`fields.${i18nKey}`)
      };
    };

    return [
      // Кнопка выбора
      {
        key: 'selected',
        label: ''
      },

      // ID
      dataField('id', 'id'),

      // Название на рус
      dataField('fullEduSubjectNameRu', 'full_edu_subject_name_ru'),

      // Краткое название  на каз
      dataField('fullEduSubjectNameKk', 'full_edu_subject_name_kk'),

      // Тип предмета
      dataField('kind', 'kind'),

    ];
  }

  private rowsRenderTimeout: number | null = null;

  private scheduleRowsRendering() {
    this.rowsRenderTimeout = setTimeout(() => {
      this.rowsRenderTimeout = null;

      const scan = (rows: IRow[]) => {
        rows.forEach(row => {
          row.selected = this.selectedEduSubject.includes(row.data.code);
        });
      };
      scan(this.rows);

      this.rows = new Array(...this.rows);
    });
  }
  // endregion

    private selectedOrg: Org | null = store.org;

    private get selectedGu(): Dict.Gu | null {
        if (this.selectedOrg === null) return null;
        return this.selectedOrg.gu;
    }

    private get requiredRegionCode(): string | null {
        const gu = this.selectedGu;
        if (gu === null) return null;

        let result: string;
        if (gu.budgetType === '02') {
            const idRegion = gu.idRegion;
            const idRegionPart = (
                idRegion.length > 2 ?
                    idRegion.substr(0, 2)
                    :
                    idRegion
            );
            result = idRegionPart + '0101';
        } else {
            result = gu.idRegion;
        }

        return result;
    }


  private reload() {
    if (this.loading) {
      console.error('Cannot load subjects - loading is going');
      return;
    }

    const regionCode = this.requiredRegionCode;
    if (regionCode === null) {
      console.error('Cannot load subjects - region code is null');
      return;
    }

    this.loading = true;

    Ax<Utils.PaginationList<EduSubject>>(
        {
          url: '/api/budget/staffing_table/education-subjects',
          method: 'GET',
          params: {
            'region-code': regionCode,
            'org-type-code': this.orgCode,
            'search-text': this.searchText.trim(),
            'items-per-page': this.itemsPerPage,
            'current-page': this.page
          },
          responseType: 'json'
        },
        list => {
          this.page = list.page;
          this.totalItems = list.itemCount;

          const rows: IRow[] = [];

          list.items.forEach(subject => {
            const row: IRow = {
              id: String(subject.id),
              code: subject.code,
              data: subject,
              original: null,
              selected: this.selectedEduSubject.includes(subject.code),
              changed: false,
              invalid: false,
              _rowVariant: null,
              errors: {},
              inputValues: {}
            };
            rows.push(row);
          });

          this.rows = rows;
          this.scheduleRowsRendering();
        },
        error => this.toast('danger', this.getTranslate('error.cannot_load_subjects'), error.toString()),
        () => {
          this.loading = false;
        }
    );
  }

  // region Организации образования - типы организаций
  private eduOrgTypes: Array<Dict.StEduOrgType> = [];
  private eduOrgTypesLoading = false;

  private get eduOrgTypeOptions(): Array<Comp.DropdownItemDef<Dict.StEduOrgType | null>> {
    const result: Array<Comp.DropdownItemDef<Dict.StEduOrgType | null>> = [];

    const locale = this.$i18n.locale.trim().toLowerCase()
    this.eduOrgTypes.forEach((eduOrgType) => {
      const code = eduOrgType.code;

      let name: string;
      switch (locale) {
        case 'en':
          name = eduOrgType.nameEn;
          break;
        case 'kk':
          name = eduOrgType.nameKk;
          break;
        default:
          name = eduOrgType.nameRu;
          break;
      }

      result.push({
        text: `${code} - ${name}`,
        value: eduOrgType,
      });
    });

    return result;
  }

  private get getEduSubjectValue(): Dict.StEduOrgType | null {
    const foundOrgType = this.eduOrgTypes.find(orgType => orgType.code === this.orgCode);
    return foundOrgType ?? null;
  }

  private handleOrgTypeChange(valuet: Dict.StEduOrgType) {
    this.orgCode = valuet.code

  }

  private reloadEduOrgTypes() {
    if (this.eduOrgTypesLoading) {
      console.error('Cannot reload EDU org types - another loading is running');
      return;
    }

    this.eduOrgTypesLoading = true;
    Ax<Utils.PaginationList<Dict.StEduOrgType>>(
        { url: `/api/budget/staffing_table/edu-orgtype?items-per-page=10000` },
        (data) => {
            this.eduOrgTypes = data.items.filter(orgType => !['01', '07', '08'].includes(orgType.code));
        },
        (error) => {
          this.toast('danger', this.getTranslate('error.cannot_load_edu_org_types'), error.toString());
        },
        () => {
          this.eduOrgTypesLoading = false;
        },
    );
  }



    private unselect(subject: string) {
        const index = this.selectedEduSubjects.indexOf(subject);
        if (index >= 0) {
            this.selectedEduSubjects.splice(index, 1);
        }

        const row = this.findByEduSubjectCode(subject);
        if (row !== null) {
            row.selected = false;
            this.scheduleRowsRendering();
        }
    }
    // endregion

    private findByEduSubjectCode(code: string): IRow | null {
        for (const row of this.rows) {
            if (row.data.code === code) {
                return row;
            }
        }
        return null;
    }

  private toggleRowSelection(row: IRow) {
    setTimeout(() => {
      const index = this.selectedEduSubjects.indexOf(row.code);

      if (index >= 0) {
        this.selectedEduSubjects.splice(index, 1);
        row.selected = false;
        } else {
        this.selectedEduSubjects.push(row.code);
        row.selected = true;
        }
      this.scheduleRowsRendering();
    });
  }
  // endregion
}
