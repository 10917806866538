




























import { Component, Model, Vue } from 'vue-property-decorator';
import { Dict } from '../types';
import { Values } from 'vue-i18n';
import EduLevelSelection from "@/modules/budget/staffing-table/education/EduLevelSelection.vue";

  /**
   * Уровень образования
   */
   interface EduLevel extends Dict.DictEducationLevel {

  /**
   * ID
   */
  id: number;
}

const modelChangeEvent = 'change';

 @Component({
     components: {
         EduLevelSelection
     }
 })
export default class EducationLevelCell extends Vue {

    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Object,
        required: false,
        default: null
    })
    public readonly value!: EduLevel | null;

    private get valueId(): number | null {
        if (this.value === null) {
            return null;
        }
        return this.value.id;
    }

    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        if (this.value === null) {
            this.selectedEduLevel = [];
        } else {
            this.selectedEduLevel = [this.value];
        }

        this.$watch('modalVisible', () => {
            if (this.modalVisible) {
                if (this.value === null) {
                    this.selectedEduLevel = [];
                } else {
                    this.selectedEduLevel = [this.value];
                }
            }
        });
    }
    // endregion

    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.*EduLevelCell*.${key}`, values));
    }

    private getEduLevelText(eduLevel: EduLevel): string {
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            return eduLevel.nameKk;
        }
        return eduLevel.nameRu;
    }
    // endregion

    // region Основное
    private selectedEduLevel: EduLevel[] = [];

    private get selectedEduLevelId(): EduLevel | null {
        if (this.selectedEduLevel.length === 0) {
            return null;
        }
        return this.selectedEduLevel[0];
    }

    private get selectedPositionId(): number | null {
        if (this.selectedEduLevelId === null) {
            return null;
        }
        return this.selectedEduLevelId.id;
    }
    // endregion

    // region Модальное окно
    private modalVisible = false;


    private onModalCancelled() {
        if (this.value === null) {
            this.selectedEduLevel = [];
        } else {
            this.selectedEduLevel = [this.value];
        }
    }

    private onModalApplied() {
        if (this.selectedPositionId !== this.valueId) {
            this.$emit(modelChangeEvent, this.selectedEduLevelId);
        }
    }
    // endregion

}
