








































































































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import {Comp, Dict, Utils} from '../types';
import PaginationPages from "@/modules/budget/staffing-table/components/PaginationPages.vue";


/**
 * Образование
 */
interface EduLevel extends Dict.DictEducationLevel { id: number }

/**
 * Строка таблицы
 */
interface IRow extends Comp.TableRow<EduLevel> {}

const modelChangeEvent = 'change';

const hasDifference = (list1: EduLevel[], list2: EduLevel[]): boolean => {
    if (list1.length !== list2.length) {
        return true;
    }

    const ids1 = list1.map(item => item.id).sort();
    const ids2 = list2.map(item => item.id).sort();
    for (let i = 0; i < ids1.length; i++) {
        if (ids1[i] !== ids2[i]) {
            return true;
        }
    }

    return false;
};

const itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [
  { value: 10, text: '10' },
  { value: 15, text: '15' },
  { value: 25, text: '25' },
];

@Component({
  components: {
    Pagination: PaginationPages
  }
})
export default class EduLevelSelection extends Vue {

    @Model(modelChangeEvent, {
        type: Array,
        default: () => [],
        required: true,
    })
    public readonly value!: EduLevel[];

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly multiple!: boolean;

    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.selectedEduLevel = this.value;

        this.$watch('value', () => {
            if (hasDifference(this.value, this.selectedEduLevel)) {
                this.selectedEduLevel = new Array(...this.value);
            }
        });

        this.$watch('multiple', () => {
            if ((!this.multiple) && (this.selectedEduLevel.length > 1)) {
                this.selectedEduLevel = [this.selectedEduLevel[0]];
            }
        });

      this.$watch('itemsPerPage', () => {
        this.page = 0;
        this.reload();
      });


        this.$watch('selectedEduLevel', () => {
            if (hasDifference(this.value, this.selectedEduLevel)) {
                this.$emit(modelChangeEvent, this.selectedEduLevel);
            }
        });

        this.$watch('trimmedSearchText', () => {
            this.reload();
        });
    }

    private mounted() {
        this.reload();

        setTimeout(() => {
            const searchTextField = this.$refs.searchTextField;
            if (searchTextField instanceof Vue) {
                const el = (searchTextField as Vue).$el;
                if (el instanceof HTMLInputElement) {
                    el.focus();
                }
            }
        });
    }
    // endregion

    // region Утилиты
  // region Утилиты
  private getTranslate(key: string, values?: Values): string {
    return String(this.$t(`modules.budget.staffing_table.*EduLevelSelection*.${key}`, values));
  }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

  // region Навигация по страницам
    private itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [...itemsPerPageVariants];

    private itemsPerPage = 10;

    private page = 0;

    private totalItems = 0;

  private onPageChanged(page: number) {
    this.page = page;
    this.reload();
  }
  // endregion

    private loading = false;

    // region список загруженных элементов
    private rows: IRow[] = [];

    // region Текст для поиска
    private searchText = '';

    private get trimmedSearchText(): string {
        return this.searchText.trim();
    }
    // endregion

    // region Выбранные элементы
    private selectedEduLevel: EduLevel[] = [];
    private get selectedEduLevelIds(): number[] {
        const result: number[] = [];

        this.selectedEduLevel.forEach(education => {
            result.push(education.id as number);
        });

        return result;
    }

    private getEducationFullText(education: EduLevel): string {
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            return education.nameKk;
        }
        return education.nameRu;
    }

  private getEducationShortText(education: EduLevel): string {
    if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
      return education.shortNameKk ?? '';
    }
    return education.shortNameRu ?? '';
  }

    private getEductionText(education: EduLevel): string {
        const text = this.getEducationFullText(education);
        if (text.length > 50) {
            return text.substr(0, 47) + '...';
        }
        return text;
    }

    private findByEduLevelId(id: number): IRow | null {
        for (const row of this.rows) {
            if (row.data.id === id) {
                return row;
            }
        }
        return null;
    }

    private unselect(education: EduLevel) {
        const index = this.selectedEduLevel.indexOf(education);
        if (index >= 0) {
            this.selectedEduLevel.splice(index, 1);
        }

        const row = this.findByEduLevelId(education.id);
        if (row !== null) {
            row.selected = false;
            this.scheduleRowsRendering();
        }
    }

    // region Строки таблицы
    private get fields(): Comp.TableFieldDef[] {
        const dataField = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.getTranslate(`fields.${i18nKey}`)
            };
        };

        return [
            // Кнопка выбора
            {
                key: 'selected',
                label: ''
            },

            // ID
          dataField('id', 'id'),

          // Название на русском
          dataField('fullEducationName', 'full_education_name'),

          // Краткое название  на русском
          dataField('shortEducationName', 'short_education_name'),

        ];
    }

    private rowsRenderTimeout: number | null = null;

    private scheduleRowsRendering() {
        this.rowsRenderTimeout = setTimeout(() => {
            this.rowsRenderTimeout = null;

            const scan = (rows: IRow[]) => {
                rows.forEach(row => {
                    row.selected = this.selectedEduLevelIds.includes(row.data.id);
                });
            };
            scan(this.rows);

            this.rows = new Array(...this.rows);
        });
    }
    // endregion

    private reload() {
        if (this.loading) {
            return;
        }
        this.loading = true;

        Ax<Utils.PaginationList<EduLevel>>(
            {
              url: '/api/budget/staffing_table/education-level',
              method: 'GET',
              params: {
                'search-text': this.searchText.trim(),
                'items-per-page': this.itemsPerPage,
                'current-page': this.page
              },
              responseType: 'json'
            },
            list => {

              this.page = list.page;
              this.totalItems = list.itemCount;

                const rows: IRow[] = [];

                list.items.forEach(eduLevel => {
                    const row: IRow = {
                        id: String(eduLevel.id),
                        data: eduLevel,
                        original: null,
                        selected: this.selectedEduLevelIds.includes(eduLevel.id),
                        changed: false,
                        invalid: false,
                        _rowVariant: null,
                        errors: {},
                        inputValues: {}
                    };
                    rows.push(row);
                });

                this.rows = rows;
                this.scheduleRowsRendering();
            },
            error => this.toast('danger', this.getTranslate('error.cannot_load_positions'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }

    private toggleRowSelection(row: IRow) {
        setTimeout(() => {
            let index: number | null = null;
            for (let i = 0; i < this.selectedEduLevel.length; i++) {
                const eduLevel = this.selectedEduLevel[i];
                if (eduLevel.id === row.data.id) {
                    index = i;
                }
            }

            if (index === null) {
                if (this.multiple) {
                    this.selectedEduLevel.push(row.data);
                } else {
                    this.selectedEduLevel = [row.data];
                }

                row.selected = true;
            } else {
                this.selectedEduLevel.splice(index, 1);

                row.selected = false;
            }
            this.scheduleRowsRendering();
        });
    }
    // endregion

}
